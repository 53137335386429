@font-face {
  font-family: "regular";
  src: url("./assests/fonts/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  background-color: #f1f1f1 !important;
  font-family: "regular" !important;
}

/* Login start */

@media (max-width: 768px) {
  .login-box {
    width: 450px !important;
  }
}

.pad {
  padding: 100px;
}

.login-box {
  padding: 26px;
}

.login-logo {
  width: 80px;
}

.login-bg {
  /* background-color: #4B0082; */
  background-image: url('../src/assests/images/truck3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.kannan1 {
  /* background-color: rgba(255, 255, 255, 0.645); */
  background-color: rgba(255, 255, 255, 0.272);
}

@media (max-width: 375px) {
  .kannan1 {
    width: 90px !important;

  }
}

@media (max-width: 425px) {
  .kannan1 {
    width: 370px !important;
  }
}

@media (max-width: 1024px) {
  .kannan {
    width: 480px !important;
  }
}

@media (min-width: 1440px) {
  .kannan {
    width: 510px !important;
  }
}

/* Login end */

/* maincontent */

/* .stick-bottom {
  position: sticky;
  z-index: 99;
  bottom: 0;
  background: #f8f8f8;
}

.stick-top {
  position: sticky;
  z-index: 99;
  top: 0px;
} */



.scroll {
  height: 250px;
  overflow-y: scroll;
  scrollbar-width: thin;
}


/*Excel Upload start */

.file-upload-label {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background-color: #4B0082;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-label:hover {
  background-color: #4B0082;
}

.upload-icon {
  margin-right: 10px;
}

.excelsubmit {
  background-color: #4B0082 !important;
  color: white !important;
  width: 160px !important;
  height: 45px !important;
  font-size: 18px !important;
}

/*Excel Upload finish */


/*Table EDIT And delete and arrow start */
.userEdit {
  font-size: 35px !important;
  padding: 5px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
  transition: background-color 1s ease, color 1s ease;
}

.icon-edit {
  background-color: #4B0082;
  color: white;
}

/*Table EDIT And delete and arrow finish */



.user-id button {
  color: #1e1d1d !important;
  font-size: 30px;
}

/* products page */


.uploadmis {
  display: flex;
  justify-content: center;
}

.tbxbox {
  font-size: 45px !important;
  margin-top: -5px !important;
}

/*Mis  start */
.boxsize {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: background-color 1s ease, color 1s ease;
  height: 110px !important;
}

.boxsize:hover {
  background-color: #4B0082;
}

.userEditMis {
  font-size: 55px !important;
  padding: 5px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
  transition: background-color 1s ease, color 1s ease;
}

.icon-edit-Mis {
  background-color: #4B0082;
  color: white;
}


.boxsize:hover .StudentList {
  color: white;
}

.boxsize:hover .icon-edit-Mis {
  background-color: white;
  color: #4B0082;
}

.img-icons {
  width: 62px !important;
}

/*Mis  End */







/* toggle switch end */
@media (min-width: 1px) and (max-width: 767.98px) {

  #main,
  .main {
    padding: 0px !important;
    height: 200vh !important;
  }

  .inside {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {}




/*dashboard Start*/
.date-container {
  margin-top: 50px;
}

.date-card {
  padding: 30px 20px;
  border-radius: 15px;
  color: #fff;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px !important;
 cursor: pointer;
}

/* Adjust font sizes and spacing */
.date-card h2 {
  font-size: 2.5rem;
  /* Smaller for better responsiveness */
  margin: 0;
}

.date-card p {
  font-size: 1.2rem;
  margin-top: 10px;
}

/* Responsive icon size */
.icon {
  font-size: 3rem;
  /* Smaller icon size for responsiveness */
  margin-bottom: 15px;
}

/* Background colors */
.day {
  background-color: #f39c12;
}

.month {
  background-color: #3498db;
}

.year {
  background-color: #2ecc71;
}
.sales{
  background-color: #257180;
}

/* Hover effect */
.date-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .date-card h2 {
    font-size: 2rem;
    /* Smaller for mobile */
  }

  .icon {
    font-size: 2.5rem;
    /* Smaller for mobile */
  }
}
.product-card {
  background-color: #fff;
  border: 2px solid #ddd; /* Initial border color */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  color: #333;
}

.product-card h5 {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.product-card p {
  margin: 5px 0;
  color: #555;
}

/* Hover Effect */
.product-card:hover {
  border-color: #007bff; /* Change border color on hover */
  transform: scale(1.05); /* Slightly enlarge the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a deeper shadow on hover */
}


/*dashboard Finish*/

.my-confirm-button-class {
  background-color: red !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.my-cancel-button-class {
  background-color: red !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  margin: 0px 25px !important;
}

.my-ok-button-class {
  background-color: green !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.my-confirm-button-class:hover,
.my-cancel-button-class:hover {
  opacity: 0.8;
  /* Add hover effect */
}

.daily-generation-section {
  max-width: 900px;
  /* Adjust the max-width as per your design */
  margin: 0 auto;
  padding: 10px;
}

.fixed-button-bar {
  position: fixed;
  bottom: 0;
  left: 228px;
  width: calc(100% - 220px);
  background-color: #fff;
  /* Adjust the background color */
  padding: 1px;

  z-index: 1000;
}

.fixed-button-bar .d-flex {
  justify-content: center;
}

.fixed-button-bar button {
  background-color: orange;
  color: white;
}

.deliverydelete-table{
  width: 120px !important;
  height: 30px !important;
}
.summan{
  font-size: 90px;
}